//
// Tables
//
// stylelint-disable max-nesting-depth, selector-no-type, selector-max-type

.table-wrapper {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: $sp-5;
  overflow-x: auto;
  border-radius: $border-radius;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
}

table {
  display: table;
  min-width: 100%;
  border-collapse: separate;
}

th,
td {
  @include fs-3;
  min-width: 120px;
  padding-top: $sp-2;
  padding-right: $sp-3;
  padding-bottom: $sp-2;
  padding-left: $sp-3;
  background-color: $table-background-color;
  border-bottom: $border rgba($border-color, 0.5);
  border-left: $border $border-color;

  &:first-of-type {
    border-left: 0;
  }
}

tbody {
  tr {
    &:last-of-type {
      th,
      td {
        border-bottom: 0;
      }

      td {
        padding-bottom: $sp-3;
      }
    }
  }
}

thead {
  th {
    border-bottom: $border $border-color;
  }
}
