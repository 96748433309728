//
// Main nav, breadcrumb, etc...
//
// stylelint-disable selector-no-type, max-nesting-depth, selector-max-compound-selectors, selector-max-type, selector-max-specificity

.nav-list {
    padding: 0.5em;
    margin-top: 5px;
    margin-bottom: 0;
    list-style: none;
    background-color: #CAE7D5;
    border-radius: 15px;


    .nav-list-item {
        @include fs-4;
        position: relative;
        margin: 0;
        font-weight: 500;
        padding-bottom: 0.15em;

        @include mq(md) {
            @include fs-3;
        }

        .nav-list-link {
            display: flex;
            align-items: center;
            min-height: $nav-list-item-height-sm;
            padding-top: 0.15rem;
            padding-bottom: 0.15rem;
            line-height: #{$nav-list-item-height-sm - 2 * $sp-1};

            @if $nav-list-expander-right {
                padding-right: $nav-list-item-height-sm;
                padding-left: $gutter-spacing-sm;
            }
            @else {
                padding-right: $gutter-spacing-sm;
                padding-left: $nav-list-item-height-sm;
            }

            @include mq(md) {
                min-height: $nav-list-item-height;
                line-height: #{$nav-list-item-height - 2 * $sp-1};

                @if $nav-list-expander-right {
                    padding-right: $nav-list-item-height;
                    padding-left: $gutter-spacing;
                }
                @else {
                    padding-right: $gutter-spacing;
                    padding-left: $nav-list-item-height;
                }
            }

            &.active {
                font-weight: 500;
                text-decoration: none;
                border-radius: 15px;
                color: white;
            }

            &:hover {
                background-image: linear-gradient( -90deg, rgba($feedback-color3, 1) 0%, rgba($feedback-color3, 0.8) 100%, rgba($feedback-color3, 0) 100%);
                border-radius: 15px;
            }

            &.active {
                background-image: linear-gradient( -90deg, rgba($feedback-color2, 1) 0%, rgba($feedback-color2, 0.8) 100%, rgba($feedback-color2, 0) 100% );
                color: white;
            }
        }

        .nav-list-icon {
          height: 24px;
          width: 24px;
          margin-right: 6px; 
          margin-left:5px;
        }

        .nav-list-expander {
            position: absolute;
            margin-right: 1em;
            border-radius: 15px;

            @if $nav-list-expander-right {
                right: 0;
            }

            width: $nav-list-item-height-sm;
            height: $nav-list-item-height-sm;
            padding-top: calc($nav-list-item-height-sm / 4);
            padding-right: calc($nav-list-item-height-sm / 4);
            padding-bottom: calc($nav-list-item-height-sm / 4);
            padding-left: calc($nav-list-item-height-sm / 4);
            color: $link-color;

            @include mq(md) {
                width: $nav-list-item-height;
                height: $nav-list-item-height;
                padding-top: calc($nav-list-item-height / 4);
                padding-right: calc($nav-list-item-height / 4);
                padding-bottom: calc($nav-list-item-height / 4);
                padding-left: calc($nav-list-item-height / 4);
            }

            &:hover {
                background-image: linear-gradient( -90deg, rgba($feedback-color3, 1) 0%, rgba($feedback-color3, 0.8) 100% );
                color: #306b47;
            }

            @if $nav-list-expander-right {
                svg {
                    transform: rotate(90deg);
                    webkit-transition: all 0.1s ease-in-out 0.15s;
                    -moz-transition: all 0.1s ease-in-out 0.15s;
                    -o-transition: all 0.1s ease-in-out 0.15s;
                    -ms-transition: all 0.1s ease-in-out 0.15s;
                    transition: all 0.1s ease-in-out 0.15s;
                }
            }
        }

        > .nav-list {
            display: none;
            padding-left: 1em;
            list-style: none;

            .nav-list-item {
                position: relative;

                .nav-list-link {
                }

                .nav-list-expander {
                    color: black;
                }
            }
        }

        &.active {
            > .nav-list-expander svg {
                @if $nav-list-expander-right {
                    transform: rotate(-90deg);
                    color: white;
                    background-color: #306b47;
                    border: 0.25px solid white;
                    border-radius: 25px;
                    webkit-transition: all 0.1s ease-in-out 0.15s;
                    -moz-transition: all 0.1s ease-in-out 0.15s;
                    -o-transition: all 0.1s ease-in-out 0.15s;
                    -ms-transition: all 0.1s ease-in-out 0.15s;
                    transition: all 0.1s ease-in-out 0.15s;
                }
                @else {
                    transform: rotate(90deg);
                    webkit-transition: all 0.1s ease-in-out 0.15s;
                    -moz-transition: all 0.1s ease-in-out 0.15s;
                    -o-transition: all 0.1s ease-in-out 0.15s;
                    -ms-transition: all 0.1s ease-in-out 0.15s;
                    transition: all 0.1s ease-in-out 0.15s;
                }
            }

            > .nav-list {
                display: block;
                background-color: rgba(133, 199, 158, 0.25);
                border: 0.25px solid white;
            }
        }
    }
}

.nav-category {
  padding-top: $sp-2;
  padding-right: $gutter-spacing-sm;
  padding-bottom: $sp-2;
  padding-left: $gutter-spacing-sm;
  font-weight: 600;
  text-align: end;
  text-transform: uppercase;
  border-bottom: $border $border-color;
  @include fs-2;

  @include mq(md) {
    padding-right: $gutter-spacing;
    padding-left: $gutter-spacing;
    margin-top: $gutter-spacing-sm;
    text-align: start;

    &:first-child {
      margin-top: 0;
    }
  }
}

//u

.aux-nav {
  height: 100%;
  overflow-x: auto;
  @include fs-2;

  .aux-nav-list {
    display: flex;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .aux-nav-list-item {
    display: inline-block;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  @include mq(md) {
    padding-right: $gutter-spacing-sm;
  }
}

// TEST

.filter-white {
    filter: invert(99%) sepia(5%) saturate(570%) hue-rotate(234deg) brightness(116%) contrast(100%);
}

.filter-none {
    width: 8%;
    margin-right: 8px;
    margin-left: 5px;
    vertical-align: middle;
    max-width: 22px;
}

// Breadcrumb nav

.breadcrumb-nav {
  @include mq(md) {
    margin-top: -$sp-4;
  }
}

.breadcrumb-nav-list {
  padding-left: 0;
  margin-bottom: $sp-3;
  list-style: none;
}

.breadcrumb-nav-list-item {
  display: table-cell;
  @include fs-2;

  &::before {
    display: none;
  }

  &::after {
    display: inline-block;
    margin-right: $sp-2;
    margin-left: $sp-2;
    color: white;
    content: "/";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
}
