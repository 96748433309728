//
// The basic two column layout
//

.side-bar {
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: $sidebar-color;

  @include mq(md) {
    flex-wrap: nowrap;
    position: fixed;
    width: $nav-width-md;
    height: 100%;
    flex-direction: column;
    border-right: $border $border-color;
    align-items: flex-end;
  }

  @include mq(lg) {
    width: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
    min-width: $nav-width;
  }
}

.main {
  @include mq(md) {
    position: relative;
    max-width: $content-width;
    margin-left: $nav-width-md;
  }

  @include mq(lg) {
    margin-left: calc(
      (100% - #{$nav-width + $content-width}) / 2 + #{$nav-width}
    );
  }
}

.main-content-wrap {
  @include container;
  padding-top: $gutter-spacing-sm;
  padding-bottom: $gutter-spacing-sm;

  @include mq(md) {
    padding-top: $gutter-spacing;
    padding-bottom: $gutter-spacing;
    padding-left: 2em;
  }
}

.pb {
    position: fixed;
    top: 0;
    height: 5px;
    width: 100%;
    background: #3E885B;
    z-index: 5;
}

.pc {
    position: fixed;
    top: 27%;
    left: 15%;
}

.bg {
    border-radius: 15px;
    width: 30px;
    height: 30px;
    background: #ddd;
    margin-bottom: 20px;
}

.ol2 {
    border-radius: 15px;
    width: 22px;
    height: 22px;
    background: #fff;
    position: absolute;
    margin-top: 4px;
    margin-left: 4px;
}

.main-header {
  z-index: 0;
  display: none;
  background-color: $sidebar-color;

  @include mq(md) {
    display: flex;
    justify-content: space-between;
    height: $header-height;
    background-color: $body-background-color;
    border-bottom: $border $border-color;
  }

  &.nav-open {
    display: block;

    @include mq(md) {
      display: flex;
    }
  }
}

#scroll-progress {
    position: fixed;
    top: 0;
    width: 0%;
    height: 4px;
    background: #7983ff;
    z-index: 10000;
}


.site-nav,
.site-header,
.site-footer {
  width: 100%;

  @include mq(lg) {
    width: $nav-width;
  }
}

.site-nav::-webkit-scrollbar {
    display: none;
}

.site-nav {
  display: none;

  &.nav-open {
    display: block;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  @include mq(md) {
    display: block;
    padding-bottom: $gutter-spacing-sm;
    overflow-y: auto;
    flex: 1 1 auto;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.site-header {
  display: flex;
  min-height: 150px;
  align-items: center;

  @include mq(md) {
    height: 150px;
    max-height: 150px;
    border-bottom: $border $border-color;
  }
}

.site-header1 {
    display: flex;
    min-height: $header-height;

    @include mq(md) {
        height: $header-height;
        max-height: $header-height;
        border-bottom: $border $border-color;
    }
}

.site-title {
  @include container;
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: $sp-3;
  padding-bottom: $sp-3;
  color: $body-heading-color;
  font-weight: 600;
  @include fs-6;

  @include mq(md) {
    padding-top: $sp-2;
    padding-bottom: $sp-2;
  }
}

@if variable-exists(logo) {
  .site-logo {
    width: 100%;
    height: 100%;
    background-image: url($logo);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.site-button {
  display: flex;
  height: 60%;
  padding: $gutter-spacing-sm;
  align-items: center;
  border-radius: 15px;
  margin:1em;
}

@include mq(md) {
  .site-header .site-button {
    display: none;
  }
}

.site-title:hover {
  background-image: linear-gradient(
    -90deg,
    rgba($feedback-color, 1) 0%,
    rgba($feedback-color, 0.8) 80%,
    rgba($feedback-color, 0) 100%
  );
}

.site-button:hover {
    background-color: #3E885B;
    color: white; 
}

// stylelint-disable selector-max-type

body {
  position: relative;
  padding-bottom: $sp-10;
  overflow-y: scroll;

  @include mq(md) {
    position: static;
    padding-bottom: 0;
  }
}

// stylelint-enable selector-max-type

.site-footer {
  @include container;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: $sp-4;
  padding-bottom: $sp-4;
  color: $grey-dk-000;
  @include fs-2;

  @include mq(md) {
    position: static;
    justify-self: end;
  }
}

.icon {
  width: $sp-5;
  height: $sp-5;
  color: #ffffff;
}
