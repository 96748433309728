// Custom CSS for alert boxes
.alert {
    width: 100%;
    margin: 10px auto;
    padding: 15px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 15px 5px #ccc;
}

@mixin alert($name, $bgColor) {
    $accentColor: darken($bgColor, 50);

    .#{$name} {
        background-color:#{$bgColor};
        border-left: 5px solid $accentColor;

        .close {
            border-color: $accentColor;
            color: $accentColor;
        }
    }
}

@include alert(danger-alert, #f7a7a3);
@include alert(simple-alert, #ebebeb);
@include alert(warning-alert, #ffd48a);
@include alert(success-alert, #CAE7D5);
@include alert(notification-alert, #a78aff);


// Custom CSS for video players
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}